/* eslint-disable import/no-unused-modules */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from '../../components/common';
import SEO from '../../components/common/SEO';
import { useViewport } from '../../hooks';
import DesktopEventPage from '../../components/common/DesktopEventPage';
import MobileEventPage from '../../components/common/MobileEventPage';
import banner from '../../images/events/2024/futuresydney/future-of-tv-advertising-desktop-hero.jpg';
import mobileBanner from '../../images/events/2024/futuresydney/future-of-tv-advertising-mobile-hero.jpg';
import ogimage from '../../images/events/2024/futuresydney/future-of-tv-advertising-og-image.jpg';

export default function FutureSydney2024() {
    const { t } = useTranslation();

    const { width } = useViewport();
    const breakpoint = 500;

    return (
        <Layout title='The Future of TV Advertising Sydney 2024 - Witbe' isEvents>
            <SEO
                title='Meet Witbe‘s team at Future of TV Advertising Sydney'
                ogDescription='Meet Witbe’s team members at the Future of TV Advertising Sydney conference on March 6, 2024! Learn more about our Ad Monitoring and Matching technology.'
                description='Discover how you can improve your QoE with our video testing and monitoring technology, now faster with more AI.'
                article='article'
                image={ogimage}
            />

            {width < breakpoint ? <MobileEventPage id={7} banner={mobileBanner} /> : <DesktopEventPage id={7} banner={banner} />}
        </Layout>
    )
}
